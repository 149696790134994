<template>
  <div id="pptlocal">
    <div class="title">*图文资源上传</div>
    <div class="main">
      <label for="name" id="names">
        <p>资源名称<span>*</span></p>
        <el-input
          v-model="name"
          id="names"
          placeholder="请输入资源名称"
        ></el-input>
      </label>

      <label for="imgs" id="imgs">
        <p>上传图片<span>*</span></p>
        <el-upload
          class="avatar-uploader"
          accept=".jpg, .jpeg, .png"
          action="false"
          multiple
          list-type="picture"
          :file-list="imglist"
          :on-remove="beforeRemove"
          :on-exceed="extend"
          :show-file-list="true"
          :http-request="upload"
          :limit="9"
        >
          <div
            class="img"
            style="max-width: 952px; display: flex; flex-wrap: wrap"
          >
            <!-- <img
              v-for="(i, v) in imglist"
              :key="v"
              :src="status == 0 ? i.url : i"
              class="avatar"
            /> -->
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </div>
          <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
        </el-upload>
      </label>
      <div class="sinfo">建议上传尺寸：600*450的jpg或png格式图片</div>
      <div class="txt">
        <p>输入文字<span>*</span></p>
        <div class="text"></div>
      </div>
      <div class="addbtn" @click="local">确认上传</div>
    </div>
  </div>
</template>
<script>
import * as util from "@/unit/network";
import wangEditor from "wangeditor";
export default {
  data() {
    return {
      name: "",
      imageUrl: "",
      file: "",
      names: "",
      uploadthing: "", // 上传接口
      accept: "", // 上传文件限制
      imglist: [],
      ff: [],
      status: 0,
      // ibox: true,
    };
  },
  mounted() {
    const editor = new wangEditor(`.text`); //题干

    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };

    // 创建编辑器
    editor.config.height = 128;
    editor.config.excludeMenus = [
      "emoticon",
      "video",
      "justify",
      "code",
      "quote",
      "emoticon",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "indent",
      "image",
      "table",
      "lineHeight",
    ];
    // this.uploadImg(editor);
    editor.create();

    this.editor = editor;

    util.post("/catalogFileInfo", { id: this.$route.query.dataid }, (res) => {
      console.log(res);
      if (res.data.status == 1) {
        this.status = res.data.status;
        this.name = res.data.file_name;
        // this.imglist = res.data.file_path.split(",");
        this.editor.txt.html(res.data.text);
        this.imageUrl = 1;
      }
    });
  },
  methods: {
    local() {
      util.post(
        "/catalogFileTuWen",
        {
          file_name: this.name,
          file_path: this.ff,
          id: this.$route.query.dataid,
          text: this.editor.txt.text(),
          content: this.editor.txt.html(),
        },
        (res) => {
          if (res.code == 1) {
            this.$router.push({
              path: "/main/addscene",
              query: { csid: this.$route.query.csid },
            });
          }
        }
      );
    },
    upload(e) {
      this.status = 0;
      this.imageUrl = "";
      // this.imglist = [];
      let formData = new FormData();
      formData.append("file", e.file);
      util.post_file("/upload_img", formData, (res) => {
        this.imageUrl = res.data;
        this.imglist.push({ url: res.data });
        this.ff.push(res.data);
      });
    },
    // 超出限制数量
    extend(file, fileList) {
      console.log(file.url);
      console.log(fileList, "1111111111");
      // this.imglist = fileList;
      console.log(fileList.length);
      this.$message({
        message: "最多可以上传9张图片",
        type: "warning",
      });
    },
    beforeRemove(file, fileList) {
      // console.log(file.url);
      // console.log(fileList);
      // console.log(this.ff);
      this.imglist = fileList;
      let arr = this.ff.filter((item) => item != file.url);
      console.log(arr);
      this.ff = arr;
    },
  },
};
</script>
<style lang="less" scoped>
#pptlocal {
  padding: 35px 44px 0;
  background-color: #fff;
  height: 100%;
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #131313;
  }
  .main::-webkit-scrollbar {
    width: 0;
  }
  .main {
    margin-left: 26px;
    margin-top: 40px;
    height: calc(100% - 0.63in);
    overflow: auto;
    #names {
      margin-bottom: 37px;
    }
    .sinfo {
      font-size: 14px;
      font-weight: 400;
      color: #949595;
      margin-left: (27px + 88px);
      margin-top: 20px;
    }
    label {
      display: flex;
      p {
        width: 88/96in;
        text-align: right;
        margin-right: 28px;
        font-size: 16px;
        color: #131313;
        span {
          color: #f00;
        }
      }
    }
    .el-input {
      width: 350/96in;
      height: 40/96in;
      line-height: 40/96in;
    }
    /deep/.el-input__inner {
      border: 1px solid #949595;
      padding-left: 15px;
    }
    .txt {
      display: flex;
      margin-top: 51px;
      p {
        width: 88/96in;
        text-align: right;
        margin-right: 28px;
        font-size: 16px;
        color: #131313;
        span {
          color: #f00;
        }
      }
      .text {
        width: 692px;
      }
    }
    .addbtn {
      width: 100/96in;
      height: 36/96in;
      background: #1c5eff;
      border-radius: 5/96in;
      line-height: 36/96in;
      color: #fff;
      font-size: 14/96in;
      text-align: center;
      margin-left: 693/96in;
      margin-top: 80/96in;
      cursor: pointer;
    }
  }
}
.active {
  background-color: #1c5eff;
}
/deep/.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  // border: 1px solid #949595;
  // border-radius: 5px;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28/96in;
  color: #8c939d;
  width: 190/96in;
  height: 110/96in;
  line-height: 110/96in;
  border: 0.01rem solid #949595;
  border-radius: 0.05rem;
  text-align: center;
}
/deep/.avatar {
  width: 190/96in;
  height: 110/96in;
  display: block;
  border: 0.01rem solid #949595;
  border-radius: 0.05rem;
}

/deep/.el-upload-dragger {
  background-color: #fff;
  border: 0.01rem dashed #d9d9d9;
  border-radius: 0.06rem;
  box-sizing: border-box;
  width: 190/96in;
  height: 110/96in;
  text-align: center;
  position: relative;
  overflow: hidden;
}
/deep/.el-upload-dragger .el-icon-upload {
  font-size: 31/96in;
  color: #c0c4cc;
  margin: 18px 0 0;
  line-height: 50/96in;
}
/deep/.upload-demo {
  margin-left: 28/96in + 88/96in;
  margin-top: 38px;
  font-size: 14px;
  .el-upload__text {
    color: #949595;
  }
  .el-upload__tip {
    font-size: 14px;
    font-weight: 400;
    color: #949595;
    margin-bottom: 67px;
  }
}
/deep/.el-upload-list .el-upload-list--text {
  width: 684/96in;
}
/deep/.el-upload-list {
  display: flex;
  flex-wrap: wrap;
  width: 330px;
}
/deep/.el-upload-list--picture .el-upload-list__item {
  width: 100px;
}
</style>